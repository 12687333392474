import React from "react";
import { Layout, Button, Typography } from "procredit-bank-design-system";
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "_utils/authN-config";

const { Header, Content } = Layout;
const { Paragraph } = Typography;


const UnauthorizedPage = () => {
    const { instance } = useMsal();

    const handleSwitchAccount = () => {
        instance.loginRedirect({
            prompt: "select_account",
            scopes: loginRequest.scopes,
        });
    };

    return (
        <Layout key="unauthorized-site-layout" className="site-layout">
            <Header key="unauthorized-site-header">
                <h1>Unauthorized Access</h1>
            </Header>

            <Content key="unauthorized-site-content" style={{ padding: "20px" }}>
                <Paragraph>
                    You do not have access with this account..
                </Paragraph>

                <Button onClick={handleSwitchAccount} type="primary" style={{ marginRight: "10px" }}>
                    Switch Account
                </Button>

            </Content>
        </Layout>
    );
};

export default UnauthorizedPage;
