// React and libraries
import { useState, useEffect, useMemo, useCallback } from "react";

// Custom hooks
import { useAuth } from "_context/AuthContext";
import { useError } from "_context/ErrorContext";

// Utils
import {
    getSecurityGroupMemberships,
    getSecurityGroup,
    getInstitution,
    getInstitutionMemberships,
    getRole,
    roles,
    isAuthorized
} from "_utils/authZ-config";

/**
 * Custom hook to retrieve claims and basic information of the logged-in user.
 * @returns {Object} An object containing user data, error, and loading state.
 */
export const useUser = () => {
    const { auth } = useAuth();
    const { addError } = useError();
    const [userState, setUserState] = useState({ user: null, error: null, isLoading: true });

    const fetchUser = useCallback(async () => {
        if (!auth || auth.isLoading) {
            setUserState(prevState => ({ ...prevState, isLoading: true }));
            return;
        }

        if (!auth.data) {
            const error = new Error("Authentication object is missing.");
            addError(error);
            setUserState({ user: null, error, isLoading: false });
            return;
        }

        setUserState(prevState => ({ ...prevState, isLoading: true }));

        try {
            const user = {
                name: auth.data.name || "Unknown",
                login: auth.data.login || "Unknown",
                groups: getSecurityGroupMemberships(auth.data.groupsMembershipIds || []),
                institutions: getInstitutionMemberships(auth.data.groupsMembershipIds || []),
                group: getSecurityGroup(auth.data.groupId),
                institution: getInstitution(auth.data.groupId),
                role: getRole(auth.data.groupId) || roles.unauthorized,
                isGroupAMLOfficer: isAuthorized(getRole(auth.data.groupId), roles.group_AML_Officer),
            };
            setUserState({ user, error: null, isLoading: false });
        } catch (error) {
            addError(error, { source: "useUser", action: "fetchUser" });
            setUserState({ user: null, error, isLoading: false });
        }
    }, [auth, addError]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const memoizedUserState = useMemo(() => userState, [userState]);

    return memoizedUserState;
};
