//TODO: more functional and modular structure, divide in:
// utils/securityGroups.js
// utils/institutions.js
// utils/roles.js
// utils/authorization.js


// Mapping of security groups with the security group IDs
export const securityGroups = {
    bank_AML_Officer_PCB_ALB: "db9dc587-c035-4339-aff1-f482fc79892f",
    bank_AML_Officer_PCB_BGR: "4f7e83ec-b8b6-4992-a528-879572931579",
    bank_AML_Officer_PCB_GRC: "02ac874d-d69d-4438-be59-1215a857d771",
    bank_AML_Officer_PCB_BIH: "b784abc0-441c-457e-b0cf-1164b5745af6",
    bank_AML_Officer_PCB_ECU: "c76ee779-c93d-4a24-8281-961a1f6536ef",
    bank_AML_Officer_PCB_GEO: "f44c1dea-eccd-4bf2-b2a9-c6784fb92991",
    bank_AML_Officer_PCB_DEU: "44541c08-3969-4791-b8b1-a0c37322ea54",
    bank_AML_Officer_PCB_KOS: "69bc7bb1-0133-4dc1-9642-fbedb0aa6f8b",
    bank_AML_Officer_PCB_MDA: "1dc64d1a-185e-48df-8863-17f2ca98a89a",
    bank_AML_Officer_PCB_MKD: "6aa64a65-c401-40da-bcab-5e8b807faa38",
    bank_AML_Officer_PCB_ROU: "714a16cc-0cd0-4db6-a3db-eb62eb7482ff",
    bank_AML_Officer_PCB_SRB: "7299ce56-db5e-4e51-b225-c05edcef2788",
    bank_AML_Officer_PCB_UKR: "ca567ad0-95a2-459d-92f3-56b404f90d50",
    group_AML_Officer: "a65efbf4-78e5-4594-b1d0-d1cebe1108fd",
    "technician": "048fe5e8-b52c-4d18-8ed2-8368d55bee67",
};

// Roles - All the user roles in our application.
export const roles = {
    bank_AML_Officer: "Bank AML Officer",
    group_AML_Officer: "Group AML Officer",
    technician: "Technician",
    unauthorized: "Unauthorized",
};

// Group Roles - The map of the groups of each role
export const roleMap = {
    [roles.bank_AML_Officer]: [
        securityGroups.bank_AML_Officer_PCB_ALB,
        securityGroups.bank_AML_Officer_PCB_BIH,
        securityGroups.bank_AML_Officer_PCB_BGR,
        securityGroups.bank_AML_Officer_PCB_ECU,
        securityGroups.bank_AML_Officer_PCB_GEO,
        securityGroups.bank_AML_Officer_PCB_DEU,
        securityGroups.bank_AML_Officer_PCB_KOS,
        securityGroups.bank_AML_Officer_PCB_MDA,
        securityGroups.bank_AML_Officer_PCB_MKD,
        securityGroups.bank_AML_Officer_PCB_ROU,
        securityGroups.bank_AML_Officer_PCB_SRB,
        securityGroups.bank_AML_Officer_PCB_UKR,
    ],
    [roles.group_AML_Officer]: [securityGroups.group_AML_Officer],
    [roles.technician]: [securityGroups.technician],
};

// Institutions - All the institutions using the application
export const institutions = {
    albania: { key: 2, value: "Albania" },
    bosnia: { key: 4, value: "Bosnia" },
    bulgaria: { key: 5, value: "Bulgaria" },
    greece: { key: 6, value: "Greece" },
    ecuador: { key: 7, value: "Ecuador" },
    georgia: { key: 8, value: "Georgia" },
    germany: { key: 9, value: "Germany" },
    kosovo: { key: 1, value: "Kosovo" },
    northMacedonia: {
        key: 10,
        value: "North Macedonia",
    },
    moldova: { key: 11, value: "Moldova" },
    romania: { key: 12, value: "Romania" },
    serbia: { key: 13, value: "Serbia" },
    ukraine: { key: 14, value: "Ukraine" },
    pch: { key: 999, value: "PCG" },
    technitian: { key: 666, value: "Technitian" },
};

// Group Institutions - The map of the groups of each role
export const institutionMap = {
    [securityGroups.bank_AML_Officer_PCB_ALB]: [institutions.albania],
    [securityGroups.bank_AML_Officer_PCB_BIH]: [institutions.bosnia],
    [securityGroups.bank_AML_Officer_PCB_BGR]: [institutions.bulgaria],
    [securityGroups.bank_AML_Officer_PCB_GRC]: [institutions.greece],
    [securityGroups.bank_AML_Officer_PCB_ECU]: [institutions.ecuador],
    [securityGroups.bank_AML_Officer_PCB_GEO]: [institutions.georgia],
    [securityGroups.bank_AML_Officer_PCB_DEU]: [institutions.germany],
    [securityGroups.bank_AML_Officer_PCB_KOS]: [institutions.kosovo],
    [securityGroups.bank_AML_Officer_PCB_MDA]: [institutions.moldova],
    [securityGroups.bank_AML_Officer_PCB_MKD]: [institutions.northMacedonia],
    [securityGroups.bank_AML_Officer_PCB_ROU]: [institutions.romania],
    [securityGroups.bank_AML_Officer_PCB_SRB]: [institutions.serbia],
    [securityGroups.bank_AML_Officer_PCB_UKR]: [institutions.ukraine],
    [securityGroups.group_AML_Officer]: [institutions.pch],
    [securityGroups.technician_Access]: [institutions.technitian],
};

/**
 * Get the security group object for a given security group ID.
 * @param {string} securityGroupId - The security group ID to retrieve.
 * @returns {Object} The security group object.
 */
export const getSecurityGroup = (securityGroupId) => {
    for (const [groupName, groupId] of Object.entries(securityGroups)) {
        if (groupId === securityGroupId) {
            return { key: groupId, value: groupName };
        }
    }
    return null;
};

/**
 * Get an array of security group objects for a given array of security group IDs.
 * @param {Array} securityGroupIds - An array of security group IDs to retrieve.
 * @returns {Array} An array of security group objects.
 */
export const getSecurityGroupMemberships = (securityGroupIds) => {
    const memberships = securityGroupIds.map((groupId) => getSecurityGroup(groupId));
    return memberships.filter((group) => group !== null);
};


/**
 * Get the institution object associated with a given security group ID.
 * @param {string} securityGroupId - The security group ID to retrieve the institution for.
 * @returns {Object} The institution object.
 */
export const getInstitution = (securityGroupId) => {
    for (const [groupId, institution] of Object.entries(institutionMap)) {
        if (groupId === securityGroupId) {
            return { key: institution[0].key, value: institution[0].value };
        }
    }
    return null;
};


/**
 * Get an array of institutions objects for a given array of security group IDs.
 * @param {Array} securityGroupIds - An array of security group IDs to retrieve.
 * @returns {Array} An array of institution objects.
 */
export const getInstitutionMemberships = (securityGroupIds) => {
    const memberships = securityGroupIds.map((groupId) => getInstitution(groupId));
    return memberships.filter((group) => group !== null);
};

/**
 * Get the role associated with a given security group ID.
 * @param {string} securityGroupId - The security group ID to retrieve the role for.
 * @returns {string} The role associated with the security group.
 */
export const getRole = (securityGroupId) => {
    const group = getSecurityGroup(securityGroupId);
    for (const roleKey of Object.keys(roleMap)) {
        if (roleMap[roleKey].includes(group?.key)) {
            return roleKey;
        }
    }

    return null;
};



/**
 * Check if the user's role is authorized to access based on the list of restricted roles.
 * @param {string} userRole - The current user's role.
 * @param {Array} restrictedToRoles - An array of roles that are allowed to access.
 * @returns {boolean} - Returns true if the user's role is authorized, otherwise false.
 */
export function isAuthorized(userRole, restrictedToRoles = []) {
    return restrictedToRoles.length === 0 || restrictedToRoles.includes(userRole);
}

export function parseGroupName(groupName) {
    return groupName?.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}