// React and libraries
import React, { useState, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

// Custom hooks
import { AuthenticationProvider } from "_context/AuthContext";
import { ErrorProvider } from "_context/ErrorContext";

// Layout & components
import AuthenticatedLayout from "_components/layouts/AuthenticatedLayout";
import UnauthenticatedLayout from "_components/layouts/UnauthenticatedLayout";
import ErrorBoundaryWrapper from "_error/ErrorBoundaryWrapper";
import Spinner from "_components/layouts/Spinner";

// Utils
import logger from "_utils/logger";
import { appInsights, reactPlugin } from "_utils/appInsights";

// Custom hooks
import useMsalEvents from "_hooks/useMsalEvents";

const App = ({ msalInstance }) => {
    const { instance } = useMsal();
    const [sessionExpired, setSessionExpired] = useState(false);

    useMsalEvents(instance, setSessionExpired);

    msalInstance.handleRedirectPromise()
        .then((response) => {
            if (response) {
                msalInstance.setActiveAccount(response.account);
            }
        })
        .catch((error) => {
            logger.error(new Error("Re-signing the user after session expiration."), { msalInstance, error });
        });

    const handleLoginAgain = () => {
        logger.log("Re-signing the user after session expiration.");
        instance.loginRedirect();
    };

    appInsights.trackEvent({ name: "App Loaded" });

    return (
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    errorComponent={sessionExpired ? <SessionExpired handleLoginAgain={handleLoginAgain} /> : <UnauthenticatedLayout />}
                >
                    <ErrorProvider>
                        <ErrorBoundaryWrapper>
                            <AuthenticationProvider>
                                <Suspense fallback={<Spinner />}>
                                    <AuthenticatedLayout />
                                </Suspense>
                            </AuthenticationProvider>
                        </ErrorBoundaryWrapper>
                    </ErrorProvider>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </BrowserRouter>
    );
};

const SessionExpired = ({ handleLoginAgain }) => (
    <div>
        <p>Session expired.</p>
        <button onClick={handleLoginAgain}>Sign back in</button>
    </div>
);

export default withAITracking(reactPlugin, App);