// React and libraries
import { useEffect } from "react";
import { EventType } from "@azure/msal-browser";
import logger from "_utils/logger";

const useMsalEvents = (instance, setSessionExpired) => {
    const handleMsalEvent = (event) => {
        switch (event.eventType) {
            case EventType.LOGIN_FAILURE:
                logger.error("Login failed. Session expired.", {
                    error: event.error,
                    correlationId: event.correlationId,
                });
                setSessionExpired(true);
                break;

            case EventType.LOGIN_SUCCESS:
                logger.log("Login successful.", {
                    account: event.payload?.account,
                    correlationId: event.correlationId,
                });
                break;

            case EventType.ACQUIRE_TOKEN_SUCCESS:
                logger.log("Token acquisition successful.", {
                    correlationId: event.correlationId,
                    account: event.payload?.account,
                });
                break;

            case EventType.ACQUIRE_TOKEN_FAILURE:
                logger.error("Token acquisition failed.", {
                    error: event.error,
                    correlationId: event.correlationId,
                });
                break;

            default:
                // Log other events if needed
                logger.log(`MSAL event: ${event.eventType}`, {
                    correlationId: event.correlationId,
                });
                break;
        }
    };

    useEffect(() => {
        const callbackId = instance.addEventCallback(handleMsalEvent);
        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, setSessionExpired]);

    return null;

};

export default useMsalEvents;
