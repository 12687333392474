import React, { useState } from "react";
import { useMsal } from '@azure/msal-react';
import { Layout, Collapse, Button, Typography } from "procredit-bank-design-system";

// Assets
import logo from "_assets/logoGlobe.svg";

const { Header, Content } = Layout;
const { Paragraph } = Typography;

const UnauthenticatedLayout = () => {
    const { instance, error } = useMsal();
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleLogin = () => {
        instance.loginRedirect();
    };

    return (
        <Layout key="unauthenticated-site-layout" className="site-layout">
            <Header key="unauthenticated-site-header">
                <div className="logo" style={{ flexShrink: 0, paddingLeft: 16 }}>
                    <img src={logo} alt="ProCredit" />
                </div>
                <h1>Access Required</h1>
            </Header>

            <Content key="unauthenticated-site-content" style={{ padding: "20px" }}>
                <Paragraph>
                    You are not signed in. Please log in to access the resources of this platform.
                </Paragraph>

                <Button onClick={handleLogin} type="primary" style={{ marginRight: "10px" }}>
                    Log In
                </Button>

                {/* Error Details (if any) */}
                <Collapse accordion activeKey={collapsed ? "1" : undefined}>
                    <Collapse.Panel header="Show error details" key="1" onClick={toggleCollapse}>
                        <p>{error ? error.errorMessage : "No error details available"}</p>
                    </Collapse.Panel>
                </Collapse>
            </Content>
        </Layout>
    );
};

export default UnauthenticatedLayout;
