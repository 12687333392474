// React and libraries
import React from "react";
import { Spin } from "procredit-bank-design-system";

const Spinner = ({ loading = true, size = "default" }) => {
    return (
        <Spin
            delay={50}
            spinning={loading}
            size={size}
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transCustomforms: "translate(-50%, -50%)",
                alignItems: "center",
            }}
        />
    );
};

export default Spinner;
