import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const Redirect = ({ from, to }) => {
    const location = useLocation();

    if (location.pathname === from) {
        return <Navigate to={to} />;
    }

    return <Outlet />;
};

export default Redirect;
