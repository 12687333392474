// React and libraries
import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

// Custom hooks
import { useError } from "_context/ErrorContext";

// Utils
import logger from "_utils/logger";
import { protectedResources } from "_utils/authN-config";

const graphScopes = protectedResources.graphService.scopes;
const graphEndpoint = protectedResources.graphService.endpoint;

const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthenticationProvider");
    }
    return context;
};

const fetchGroupMemberships = async (accessToken, addError) => {
    try {
        const response = await fetch(`${graphEndpoint}/me/memberOf`, {
            headers: { Authorization: `Bearer ${accessToken}` }
        });

        if (!response.ok) {
            throw new Error(`Error fetching group memberships: ${response.statusText}`);
        }

        const data = await response.json();
        return data.value.map(group => group.id);
    } catch (error) {
        logger.error("Error fetching group memberships:", error);
        addError(error);
        throw error;
    }
};

const fetchAuthData = async (instance, setAuth, addError) => {
    try {
        const account = instance.getActiveAccount() || instance.getAllAccounts()[0];
        if (!account) throw new Error("No active account found");

        let accessToken;
        try {
            accessToken = await instance.acquireTokenSilent({
                scopes: graphScopes,
                account: account,
            });
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                logger.warn("Silent token acquisition failed, acquiring token using popup");
                accessToken = await instance.acquireTokenPopup({ scopes: graphScopes });
            } else {
                logger.error("Error during token acquisition:", error);
                addError(error);
                setAuth({ isLoading: false, data: null, error });
                return;
            }
        }

        if (!accessToken?.accessToken) throw new Error("Access token is undefined or invalid");

        const groupMemberships = await fetchGroupMemberships(accessToken.accessToken, addError);
        setAuth((prevAuth) => ({
            ...prevAuth,
            isLoading: false,
            data: {
                name: account.name,
                login: account.username,
                groupsMembershipIds: groupMemberships,
                groupId: prevAuth?.data?.groupId || null,
            },
            error: null,
        }));
    } catch (error) {
        logger.error("Error fetching auth data:", error);
        addError(error);
        setAuth({ isLoading: false, data: null, error });
    }
};

export const AuthenticationProvider = ({ children }) => {
    const { instance } = useMsal();
    const [auth, setAuth] = useState({ isLoading: true, data: null, error: null });
    const { addError } = useError();

    useEffect(() => {
        fetchAuthData(instance, setAuth, addError);
    }, [instance, addError]);

    const setGroupId = (groupId) => {
        setAuth((prevAuth) => ({
            ...prevAuth,
            data: { ...prevAuth.data, groupId }
        }));
    };

    const authContextValue = useMemo(() => ({ auth, setAuth, setGroupId }), [auth]);

    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
