import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const isProduction = process.env.NODE_ENV === 'production';

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'e50752a9-876b-41f6-adc8-e023a45db316',
        extensions: [reactPlugin],
        disableTelemetry: !isProduction,
        enableDebug: !isProduction, 
        enableAutoRouteTracking: isProduction,
        samplingPercentage: 50
    },
});

appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'AML Reporting platform SPA';
    envelope.tags['ai.cloud.roleInstance'] = 'SPA';
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
