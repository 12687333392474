import { appInsights } from './appInsights';

const isProduction = process.env.NODE_ENV === 'production';

const logger = {
    log: (message, context = {}) => {
        if (isProduction) {
            // Enviar a App Insights en producci�n
            appInsights.trackEvent({
                name: message,
                properties: context,
            });
        } else {
            // Mostrar en consola en desarrollo
            console.log(`[LOG]: ${message}`, context);
        }
    },

    error: (error, context = {}) => {
        if (isProduction) {
            if (typeof error === "string") {
                error = new Error(error);
            }
            appInsights.trackException({
                exception: error,
                properties: context,
            });
        } else {
            console.error(`[ERROR]: ${error.message || error}`, context);
        }
    },

    warn: (message, context = {}) => {
        if (isProduction) {
            appInsights.trackEvent({
                name: 'CustomWarningEvent',
                properties: { message, ...context },
            });
        } else {
            console.warn(`[WARN]: ${message}`, context);
        }
    },
};

export default logger;
