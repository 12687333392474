import { LogLevel } from "@azure/msal-browser";

//const reactUri = process.env.REACT_APP_URI;
//const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const reactUri = "https://aml-reporting-platform.procredit-group.com";
const apiEndpoint = "https://pcg-prod-aml-api-webapp.azurewebsites.net/api";


export const msalConfig = {
    auth: {
        //clientId: "2a2e47d0-6b8a-48c4-a1ba-e28d6b68c216",
        clientId: "f219a08c-aaba-4fbd-ae20-513d907ec320",
        authority: "https://login.microsoftonline.com/organizations",
        validateAuthority: true,
        redirectUri: reactUri, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: reactUri, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
             
                    console.log(message);
                }
                console.log(reactUri);
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log(message);
                        return;
                }
            },
            piiLoggingEnabled: true,
            logLevel: LogLevel.Verbose,
        },
    },
};

/**
 *Endpoints and scopes when obtaining an access token for protected web APIs.
 * */
export const protectedResources = {
    graphService: {
        endpoint: "https://graph.microsoft.com/v1.0",
        scopes: ["User.Read", "GroupMember.Read.All"],
    },

    apiGroupAMLReportingPlatform: {
        endpoint: apiEndpoint,
        //scopes: ["api://f52066a7-c749-4423-962f-5ba02b63b087/access_as_user"], //scopes when obtaining an access token for protected web APIs
        scopes: ["api://3196c40d-9173-42b6-ab3f-9aeabca8da49/access_as_user"], //scopes when obtaining an access token for protected web APIs

    },
};

/**
 * Scopes will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 **/
export const loginRequest = {
    scopes: [
        ...protectedResources.graphService.scopes,
        ...protectedResources.apiGroupAMLReportingPlatform.scopes,
    ],
};

