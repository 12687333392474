import React from "react";
import { useNavigate } from "react-router-dom";
import { Result, Button, Collapse, Typography } from "procredit-bank-design-system";
import { useError } from "_context/ErrorContext";
import logger from "_utils/logger";  // Import logger for logging

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const ErrorPage = ({ url = null }) => {
    const navigate = useNavigate();
    const { error, clearError } = useError();

    const errorTitle = (status) => {
        switch (status) {
            case "success":
                return "Your operation has been successfully completed";
            case "info":
                return "Your operation has been executed";
            case "warning":
                return "There are some problems with your operation";
            case "401":
            case "403":
                return "Sorry, you are not authorized to access this page.";
            case "404":
                return "Sorry, the page you visited does not exist.";
            case "405":
                return "Network response was not ok.";
            case "500":
                return "Sorry, something went wrong.";
            default:
                return "Operation failed. Please check the following information before resubmitting.";
        }
    };

    const handleDismissError = () => {
        logger.log("User dismissed the error.");
        clearError();
    };

    const handleGoHome = () => {
        logger.log("User navigated to home from ErrorPage.");
        clearError();
        navigate("/");
    };

    const handleGoBack = () => {
        logger.log("User navigated back from ErrorPage.");
        clearError();
        navigate(-1);
    };

    // Log error details if they exist
    if (error) {
        logger.error(`Error occurred on route: ${url}`, error);
    }

    return (
        <Result
            status={error?.status || "error"}
            title={errorTitle(error?.status || "")}
            extra={
                <>
                    <Button onClick={handleGoBack}>Go Back</Button>
                    <Button type="primary" onClick={handleDismissError}>Dismiss Error</Button>
                    <Button onClick={handleGoHome}>Go to Home</Button>
                </>
            }
        >
            <div className="desc">
                <Paragraph>
                    <Text>The content you submitted has the following error:</Text>
                </Paragraph>
                {error && (
                    <Collapse accordion defaultActiveKey={['0']}>
                        <Panel header="Error Details" key="1">
                            <p>{error.message}</p>
                            {error.info && (
                                <pre>{JSON.stringify(error.info, null, 2)}</pre>
                            )}
                            @ {url}
                        </Panel>
                    </Collapse>
                )}
            </div>
        </Result>
    );
};

export default ErrorPage;
