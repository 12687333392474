import React from "react";
import { Navigate } from "react-router-dom";

// Custom hooks
import { useUser } from "_hooks/useUser";
import { useError } from "_context/ErrorContext";

// Utils
import { isAuthorized } from "_utils/authZ-config";
import logger from "_utils/logger";

// Layouts & components
import UnauthorizedLayout from "_components/layouts/UnauthorizedLayout";
import ErrorPage from "_error/ErrorPage";
import Spinner from "_components/layouts/Spinner";


const RouteGuard = ({ element, roles }) => {
    const { user, isLoading: isLoadingUser, error: errorUser } = useUser();
    const { addError } = useError();

    if (isLoadingUser) {
        logger.log("User data is loading...");
        return <Spinner />;
    }

    if (errorUser) {
        logger.error("Error fetching user data:", errorUser);
        addError(errorUser);
        return <ErrorPage url="RouteGuard" />;
    }

    if (!user) {
        logger.error("User object is null or undefined in RouteGuard.");
        return <ErrorPage url="RouteGuard - Missing User" />;
    }

    // Redirect to Unauthorized page if the user is not authorized
    if (!isAuthorized(user?.role, roles)) {
        logger.warn(`Unauthorized access attempt to route: ${window.location.pathname} by role: ${user?.role}`);
        return <UnauthorizedLayout />;
    }

    // Render the protected component
    logger.log(`User with role ${user?.role} authorized to access route: ${window.location.pathname}`);
    return element;
};

export default RouteGuard;
