// React and libraries
import React, { createContext, useContext, useState } from "react";

// Utils
import logger from "_utils/logger";

const ErrorContext = createContext();

export const useError = () => {
    return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);

    const addError = (error, errorInfo = null, level = "error") => {
        switch (level) {
            case "warning":
                logger.warn(`Warning: ${error.message}`);
                break;
            case "critical":
                logger.error(`Critical Error: ${error.message}`);
                break;
            default:
                logger.error(`Error: ${error.message}`);
        }

        if (errorInfo) {
            logger.error(`Error Info: ${JSON.stringify(errorInfo)}`);
        }

        setError({
            message: error.message,
            info: errorInfo,
            status: error.status || 500,
            level,
        });
    };

    const clearError = () => {
        logger.log("Clearing error");
        setError(null);
    };

    return (
        <ErrorContext.Provider value={{ error, addError, clearError }}>
            {children}
        </ErrorContext.Provider>
    );
};
