// React and libraries
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

// Custom hooks
import { useError } from "_context/ErrorContext";

// Utils
import logger from "_utils/logger";

// Components
import ErrorPage from "_error/ErrorPage";


const ErrorBoundaryWrapper = ({ children }) => {
    const { addError } = useError();

    const handleError = (error, errorInfo) => {
        // Log the error with additional information
        logger.error(`Error: ${error.message}`);
        logger.error(`Error Info: ${JSON.stringify(errorInfo)}`);

        // Add the error to the error context
        addError(error, errorInfo);
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={handleError}
        >
            {children}el 
        </ErrorBoundary>
    );
};

export default ErrorBoundaryWrapper;
