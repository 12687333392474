// React and libraries
import React, { useEffect } from "react";
import { Layout, Select, Result, Typography, Icons, Colors } from "procredit-bank-design-system";
import { useLocation, useNavigate } from "react-router-dom";

// Utils
import { parseGroupName } from "_utils/authZ-config";

// Custom hooks
import { useAuth } from "_context/AuthContext";
import { useUser } from "_hooks/useUser";
import { useError } from "_context/ErrorContext";

// Assets
import logo from "_assets/logoGlobe.svg";

// Components and layout
import Router from "_routing/Router";
import Spinner from "_components/layouts/Spinner";

const { Header, Content } = Layout;
const { Option } = Select;
const { Paragraph } = Typography;
const { SmileOutlined } = Icons;
const { gray } = Colors;

const AuthenticatedLayout = () => {
    const { auth, setGroupId } = useAuth();
    const { user, isLoading: isLoadingUser } = useUser();
    const { error } = useError();
    const location = useLocation();
    const navigate = useNavigate();
    const initialPath = location.pathname;

    useEffect(() => {
        if (user?.groups?.length === 1 && !error) {
            const groupId = user?.groups[0].key;

            if (auth?.data?.groupId !== groupId) {
                setGroupId(groupId);
            }

            if (initialPath !== location.pathname) {
                navigate(initialPath);
            }
        }
    }, [auth?.data?.groupId, error, initialPath, navigate, setGroupId, user?.groups]);


    const handleGroupChange = (value) => {
        setGroupId(value);
    };

    if (auth?.isLoading || isLoadingUser) {
        return <Spinner />;
    }

    if (!auth?.data) {
        return <Result status="error" title="Authentication Error" subTitle="Unable to load authentication details." />;
    }

    if (auth.data.groupId) {
        return <Router />;
    }

    return (
        <Layout key="site-layout" className="site-layout" style={{ height: "100vh", overflow: "hidden" }}>
            <Header>
                <div className="logo" style={{ flexShrink: 0, paddingLeft: 16 }}>
                    <img src={logo} alt="ProCredit" />
                </div>
            </Header>
            <Content key="site-content" style={{ width: "100%", padding: "50px 100px", background: gray[2] }}>
                <Result
                    icon={<SmileOutlined />}
                    title={`Welcome, ${auth?.data?.name || 'User'}`}
                    extra={
                        <>
                            <Paragraph>Please select your current role:</Paragraph>
                            <Paragraph>
                                <Select value={auth.data.groupId} onChange={handleGroupChange} style={{ width: 250 }}>
                                    {user && user.groups.map(group => (
                                        <Option key={group.key} value={group.key}>
                                            {parseGroupName(group.value)}
                                        </Option>
                                    ))}
                                </Select>
                            </Paragraph>
                        </>
                    }
                />
            </Content>
        </Layout>
    );
};



export default AuthenticatedLayout;
