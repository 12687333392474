// React and libraries
import React, { useEffect, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// Utils
import { roles } from "_utils/authZ-config";
import logger from "_utils/logger";

//Components and layouts
import Redirect from "_routing/Redirect";
import RouteGuard from "_routing/RouteGuard";
import Spinner from "_components/layouts/Spinner";

const AuthorizedLayout = React.lazy(() => import("_components/layouts/AuthorizedLayout"));

const QuarterlyReportMenu = React.lazy(() => import("features/quarterly-report/quarterly-reports/QuarterlyReportMenu"));
const QuarterlyReportList = React.lazy(() => import("features/quarterly-report/quarterly-reports/QuarterlyReportList"));
const ClientsOverview = React.lazy(() => import("features/quarterly-report/client-overview/ClientsOverview"));
const NRAList = React.lazy(() => import("features/quarterly-report/nras/NRAList"));
const NRADetail = React.lazy(() => import("features/quarterly-report/nras/NRADetail"));
const SARList = React.lazy(() => import("features/quarterly-report/sars/SARList"));
const SARDetail = React.lazy(() => import("features/quarterly-report/sars/SARDetail"));
const PendingSARList = React.lazy(() => import("features/quarterly-report/pending-sars/PendingSARList"));
const PendingSARDetail = React.lazy(() => import("features/quarterly-report/pending-sars/PendingSARDetail"));
const InvestigationRequestList = React.lazy(() => import("features/quarterly-report/investigation-requests/InvestigationRequestList"));
const InvestigationRequestDetail = React.lazy(() => import("features/quarterly-report/investigation-requests/InvestigationRequestDetail"));
const FineList = React.lazy(() => import("features/quarterly-report/fines/FineList"));
const FineDetail = React.lazy(() => import("features/quarterly-report/fines/FineDetail"));
const BankStaffTrainingList = React.lazy(() => import("features/quarterly-report/bank-staff-trainings/BankStaffTrainingList"));
const BankStaffTrainingDetail = React.lazy(() => import("features/quarterly-report/bank-staff-trainings/BankStaffTrainingDetail"));
const AMLControlList = React.lazy(() => import("features/quarterly-report/aml-controls/AMLControlList"));
const AMLControlDetail = React.lazy(() => import("features/quarterly-report/aml-controls/AMLControlDetail"));
const AMLRecurringControlList = React.lazy(() => import("features/quarterly-report/aml-recurring-controls/AMLRecurringControlList"));
const AMLRecurringControlDetail = React.lazy(() => import("features/quarterly-report/aml-recurring-controls/AMLRecurringControlDetail"));

const AMLStaffList = React.lazy(() => import("features/aml-staff-report/aml-staff/AMLStaffList"));
const AMLStaffDetail = React.lazy(() => import("features/aml-staff-report/aml-staff/AMLStaffDetail"));
const AMLStaffTrainingList = React.lazy(() => import("features/aml-staff-report//aml-staff-trainings/AMLStaffTrainingList"));
const AMLStaffTrainingDetail = React.lazy(() => import("features/aml-staff-report/aml-staff-trainings/AMLStaffTrainingDetail"));

const AuditReportList = React.lazy(() => import("features/audit-follow-up-report/audit-reports/AuditReportList"));
const AuditReportDetail = React.lazy(() => import("features/audit-follow-up-report/audit-reports/AuditReportDetail"));
const AuditFindingList = React.lazy(() => import("features/audit-follow-up-report/audit-findings/AuditFindingList"));
const AuditFindingDetail = React.lazy(() => import("features/audit-follow-up-report/audit-findings/AuditFindingDetail"));
const AgreedActionList = React.lazy(() => import("features/audit-follow-up-report/agreed-actions/AgreedActionList"));
const AgreedActionDetail = React.lazy(() => import("features/audit-follow-up-report/agreed-actions/AgreedActionDetail"));

const TopicList = React.lazy(() => import("features/legal-framework-report/topics/TopicList"));
const TopicDetail = React.lazy(() => import("features/legal-framework-report/topics/TopicDetail"));
const RegulationComparisonList = React.lazy(() => import("features/legal-framework-report/regulation-comparisons/RegulationComparisonList"));
const RegulationComparisonDetail = React.lazy(() => import("features/legal-framework-report/regulation-comparisons/RegulationComparisonDetail"));
const PolicyComparisonList = React.lazy(() => import("features/legal-framework-report/policy-comparisons/PolicyComparisonList"));
const PolicyComparisonDetail = React.lazy(() => import("features/legal-framework-report/policy-comparisons/PolicyComparisonDetail"));

const LegalEntityList = React.lazy(() => import("features/group-blacklist-report/legal-entities/LegalEntityList"));
const LegalEntityDetail = React.lazy(() => import("features/group-blacklist-report/legal-entities/LegalEntityDetail"));
const PrivateIndividualList = React.lazy(() => import("features/group-blacklist-report/private-individuals/PrivateIndividualList"));
const PrivateIndividualDetail = React.lazy(() => import("features/group-blacklist-report/private-individuals/PrivateIndividualDetail"));
const BlacklistExport = React.lazy(() => import("features/group-blacklist-report/BlacklistExport"));

const Administration = React.lazy(() => import("features/administration/Administration"));
const SARTagList = React.lazy(() => import("features/administration/SARTagList"));
const SARTagDetail = React.lazy(() => import("features/administration/SARTagDetail"));
const SARTypologyList = React.lazy(() => import("features/administration/SARTypologyList"));
const SARTypologyDetail = React.lazy(() => import("features/administration/SARTypologyDetail"));
const AMLControlTopicList = React.lazy(() => import("features/administration/AMLControlTopicList"));
const AMLControlTopicDetail = React.lazy(() => import("features/administration/AMLControlTopicDetail"));
const CountryList = React.lazy(() => import("features/administration/CountryList"));
const CountryDetail = React.lazy(() => import("features/administration/CountryDetail"));
const ISICCodeList = React.lazy(() => import("features/administration/ISICCodeList"));
const ISICCodeDetail = React.lazy(() => import("features/administration/ISICCodeDetail"));

const ErrorPage = React.lazy(() => import("_error/ErrorPage"));


export const routes = [
    {
        path: "/",
        element: <AuthorizedLayout />,
        children: [
            {
                path: "Quarterly-report",
                roles: [roles.bank_AML_Officer, roles.group_AML_Officer],
                element: <Redirect from="/Quarterly-report" to="/Quarterly-report/Quarterly-reports" />,
                children: [
                    { path: "Quarterly-reports", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <QuarterlyReportList /> },
                    {
                        path: "Quarterly-reports/:quarterlyReportId",
                        roles: [roles.bank_AML_Officer, roles.group_AML_Officer],
                        element: <QuarterlyReportMenu />,
                        children: [
                            { path: "", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <Navigate to="Clients-overview" /> },
                            { path: "Clients-overview", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <ClientsOverview /> },
                            { path: "NRAs", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <NRAList /> },
                            { path: "NRAs/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <NRADetail /> },
                            { path: "SARs", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <SARList /> },
                            { path: "SARs/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <SARDetail /> },
                            { path: "Pending-SARs", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <PendingSARList /> },
                            { path: "Pending-SARs/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <PendingSARDetail /> },
                            { path: "Investigation-requests", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <InvestigationRequestList /> },
                            { path: "Investigation-requests/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <InvestigationRequestDetail /> },
                            { path: "Fines", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <FineList /> },
                            { path: "Fines/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <FineDetail /> },
                            { path: "Bank-staff-trainings", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <BankStaffTrainingList /> },
                            { path: "Bank-staff-trainings/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <BankStaffTrainingDetail /> },
                            { path: "AML-controls", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLControlList /> },
                            { path: "AML-controls/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLControlDetail /> },
                            { path: "AML-recurring-controls", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLRecurringControlList /> },
                            { path: "AML-recurring-controls/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLRecurringControlDetail /> },
                        ],
                    },
                    { path: "NRAs", roles: [roles.group_AML_Officer], element: <NRAList /> },
                    { path: "NRAs/:id", roles: [roles.group_AML_Officer], element: <NRADetail /> },
                    { path: "SARs", roles: [roles.group_AML_Officer], element: <SARList /> },
                    { path: "SARs/:id", roles: [roles.group_AML_Officer], element: <SARDetail /> },
                    { path: "Pending-SARs", roles: [roles.group_AML_Officer], element: <PendingSARList /> },
                    { path: "Pending-SARs/:id", roles: [roles.group_AML_Officer], element: <PendingSARDetail /> },
                    { path: "Investigation-requests", roles: [roles.group_AML_Officer], element: <InvestigationRequestList /> },
                    { path: "Investigation-requests/:id", roles: [roles.group_AML_Officer], element: <InvestigationRequestDetail /> },
                    { path: "Fines", roles: [roles.group_AML_Officer], element: <FineList /> },
                    { path: "Fines/:id", roles: [roles.group_AML_Officer], element: <FineDetail /> },
                    { path: "Bank-staff-trainings", roles: [roles.group_AML_Officer], element: <BankStaffTrainingList /> },
                    { path: "Bank-staff-trainings/:id", roles: [roles.group_AML_Officer], element: <BankStaffTrainingDetail /> },
                    { path: "AML-controls", roles: [roles.group_AML_Officer], element: <AMLControlList /> },
                    { path: "AML-controls/:id", roles: [roles.group_AML_Officer], element: <AMLControlDetail /> },
                    { path: "AML-recurring-controls", roles: [roles.group_AML_Officer], element: <AMLRecurringControlList /> },
                    { path: "AML-recurring-controls/:id", roles: [roles.group_AML_Officer], element: <AMLRecurringControlDetail /> },
                ],
            },
            {
                path: "AML-staff-report",
                roles: [roles.bank_AML_Officer, roles.group_AML_Officer],
                element: <Redirect from="/AML-staff-report" to="/AML-staff-report/AML-staff" />,
                children: [
                    { path: "AML-staff", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLStaffList /> },
                    { path: "AML-staff/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLStaffDetail /> },
                    { path: "AML-staff-trainings", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLStaffTrainingList /> },
                    { path: "AML-staff-trainings/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AMLStaffTrainingDetail /> },
                ],
            },
            {
                path: "Audit-follow-up-report",
                roles: [roles.bank_AML_Officer, roles.group_AML_Officer],
                element: <Redirect from="/Audit-follow-up-report" to="/Audit-follow-up-report/Audit-reports" />,
                children: [
                    { path: "Audit-reports", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AuditReportList /> },
                    { path: "Audit-reports/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AuditReportDetail /> },
                    //{ path: "Audit-findings", roles: [roles.group_AML_Officer], element: <AuditFindingList /> },
                    //{ path: "Audit-findings/:id", roles: [roles.group_AML_Officer], element: <AuditFindingDetail /> },
                    { path: "Agreed-actions", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AgreedActionList /> },
                    { path: "Agreed-actions/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <AgreedActionDetail /> },
                ],
            },
            {
                path: "Legal-framework-report",
                roles: [roles.bank_AML_Officer, roles.group_AML_Officer],
                element: <Redirect from="/Legal-framework-report" to="/Legal-framework-report/Topics" />,
                children: [
                    { path: "Topics", roles: [roles.group_AML_Officer], element: <TopicList /> },
                    { path: "Topics/:id", roles: [roles.group_AML_Officer], element: <TopicDetail /> },
                    { path: "Regulation-comparisons", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <RegulationComparisonList /> },
                    { path: "Regulation-comparisons/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <RegulationComparisonDetail /> },
                    { path: "Policy-comparisons", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <PolicyComparisonList /> },
                    { path: "Policy-comparisons/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <PolicyComparisonDetail /> },
                ],
            },
            {
                path: "Group-blacklist-report",
                roles: [roles.bank_AML_Officer, roles.group_AML_Officer],
                element: <Redirect from="/Group-blacklist-report" to="/Group-blacklist-report/Legal-entities" />,
                children: [
                    { path: "Legal-entities", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <LegalEntityList /> },
                    { path: "Legal-entities/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <LegalEntityDetail /> },
                    { path: "Private-individuals", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <PrivateIndividualList /> },
                    { path: "Private-individuals/:id", roles: [roles.bank_AML_Officer, roles.group_AML_Officer], element: <PrivateIndividualDetail /> },
                    { path: "Export-blacklist", roles: [roles.group_AML_Officer], element: <BlacklistExport /> },
                ],
            },
            {
                path: "Administration",
                roles: [roles.group_AML_Officer, roles.technician],
                element: <Administration />,
            },
            {
                path: "*",
                element: <ErrorPage url="*" />,
            },
        ],
    },
];

const Router = () => {
    const routing = useRoutes(
        routes.map(route => ({
            ...route,
            element: <RouteGuard element={route.element} roles={route.roles} />,
        }))
    );

    useEffect(() => {
        console.log(`NRouter ${window.location.pathname}`);
        logger.log(`Navigated to route: ${window.location.pathname}`);
    }, [window.location.pathname]);

    return (
        <Suspense fallback={<Spinner />}>{routing}</Suspense>
    );
};

export default Router;
